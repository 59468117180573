import React, { useState, useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { $isStickyCTAVisible } from '@/stores/ui/stores'
import Icon from '../Icon/Icon'

import './ZendeskForm.scss'
import type { CMSZendesk } from '@/types/sanity'
import dayjs from 'dayjs'

const ZENDESK_LOAD_DELAY = 5000
const ZENDESK_URL =
  'https://static.zdassets.com/ekr/snippet.js?key=23df5be6-becf-4521-abf5-358f8c753284'
const ZENDESK_ID = 'ze-snippet'

type ZendeskFormProps = {
  zendeskSettings: CMSZendesk
}

type Interval = {
  end_time: number
  start_time: number
}

type Schedule = {
  id: number
  intervals: Interval[]
  name: string
  time_zone: string
}

type ZendeskSchedulesResponse = {
  schedules: Schedule[]
}

const ZendeskForm: React.FC<ZendeskFormProps> = ({ zendeskSettings }) => {
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const [isAgentsOnline, setIsAgentsOnline] = useState(false)

  const isStickyVisible = useStore($isStickyCTAVisible)

  const openForm = () => {
    setIsFormVisible(true)
  }

  const closeForm = () => {
    setIsFormVisible(false)
  }

  const loadScript = () => {
    if (!document.querySelector(`script[src="${ZENDESK_URL}"]`)) {
      const new_script = document.createElement('script')
      new_script.id = ZENDESK_ID
      new_script.defer = true
      new_script.src = ZENDESK_URL
      document.head.appendChild(new_script)
    }
  }

  const submitForm = () => {
    closeForm()

    if (typeof window.zE !== 'undefined') {
      window.zE('messenger', 'open')
    }
  }

  const isOnline = (data: ZendeskSchedulesResponse) => {
    if (!data.schedules || !data.schedules.length) {
      return false
    }

    const currentTime = dayjs().tz('America/New_York')
    const dayOfWeek = currentTime.day()
    const hour = currentTime.hour()
    const minute = currentTime.minute()
    const totalMinutes = dayOfWeek * 24 * 60 + hour * 60 + minute

    for (const interval of data.schedules[0].intervals) {
      if (
        totalMinutes >= interval.start_time &&
        totalMinutes <= interval.end_time
      ) {
        return true
      }
    }

    return false
  }

  const checkAgentsOnline = async () => {
    try {
      const response = await fetch('/api/zendesk-schedules', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = (await response.json()) as ZendeskSchedulesResponse

      isOnline(data) ? setIsAgentsOnline(true) : setIsAgentsOnline(false)
    } catch (error) {
      console.error('Error fetching Zendesk schedules:', error)
    }
  }

  useEffect(() => {
    if (zendeskSettings.displayChatButton) {
      checkAgentsOnline()
      setTimeout(() => {
        loadScript()
        setIsButtonVisible(true)
      }, ZENDESK_LOAD_DELAY)
    }
  }, [])

  const imageSrc =
    'https://cdn.shopify.com/s/files/1/0192/0076/files/chat-icon.png'

  return (
    <>
      {isButtonVisible && (
        <button
          className={`c-zendesk-form__button type-label ${
            isStickyVisible ? 'is-stuck' : ''
          }`}
          onClick={openForm}
        >
          Chat
          <div style={{ position: 'relative' }}>
            <img
              src={imageSrc}
              alt="Chat bot"
              className="c-zendesk-form__chat-icon"
            ></img>
            {isAgentsOnline && (
              <div className="c-zendesk-form__chat-online-icon"></div>
            )}
          </div>
        </button>
      )}
      {isFormVisible && (
        <div
          className={`c-zendesk-form__form ${
            isStickyVisible ? 'is-stuck' : ''
          }`}
        >
          <div className="c-zendesk-form__header">
            <p className="c-zendesk-form__title type-label">
              CHAT WITH SUPPORT
            </p>
            <button
              className="c-zendesk-form__disclaimer-close"
              onClick={closeForm}
              onKeyUp={closeForm}
            >
              <Icon name="cross" />
            </button>
          </div>
          <form className="c-zendesk-form__content" onSubmit={submitForm}>
            <p className="c-zendesk-form__content-header type-label">
              Disclaimer
            </p>
            <p className="c-zendesk-form__content-text c-zendesk-form__content-text--subheader type-body">
              By using this chat, you:
            </p>
            <ol type="1">
              <li className="c-zendesk-form__content-text c-zendesk-form__content-text--list type-body">
                understand that an automated chatbot will collect and process
                any information you provide, and you may request to speak with a
                live agent during normal business hours (10 AM - 5PM ET).
              </li>
              <li className="c-zendesk-form__content-text c-zendesk-form__content-text--list type-body">
                understand that any information you provide may be monitored and
                retained, including by our service providers.
              </li>
              <li className="c-zendesk-form__content-text c-zendesk-form__content-text--list type-body">
                are accepting and consenting to the practices described in our{' '}
                <a href="/pages/privacy-policy">Privacy Policy.</a>
              </li>
            </ol>
            <button
              type="submit"
              className="button button--tertiary c-zendesk-form__submit-button"
            >
              Get Started
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default ZendeskForm
